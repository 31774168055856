



































































































































import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faChartPie,
  faClock,
  faDownload,
  faFile,
  faFolder,
  faGear,
  faLayerGroup,
  faRightFromBracket,
  faRocket,
  faTasks,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';
import { getStats, Project, ProjectList, Stats, User } from '../api';
import { GlobalEvents } from '../events/global-events';
import router from '../router';
import { IVersionInfo } from '../store/version.module';
import NavigationItem from './NavigationItem.vue';
import ProjectRoleBadge from './ProjectRoleBadge.vue';
import UserRoleBadge from './UserRoleBadge.vue';

library.add(
  faRocket,
  faChartPie,
  faLayerGroup,
  faClock,
  faTasks,
  faUsers,
  faArrowLeft,
  faArrowRight,
  faFile,
  faDownload,
  faGear,
  faFolder,
  faArrowUp,
  faRightFromBracket
);

const project = namespace('project');
const version = namespace('version');
const impersonation = namespace('impersonation');

@Component({ components: { NavigationItem, FontAwesomeIcon, ProjectRoleBadge, UserRoleBadge } })
export default class NavigationBar extends Vue {
  private readonly localStorageKey = 'navigationBarCollapsed';

  stats: Stats = {};

  collapsed = window.localStorage.getItem(this.localStorageKey) === 'true';

  @Getter
  me!: User | null;

  @impersonation.Getter
  impersonatedUserId!: string | null;

  @impersonation.Mutation
  private setImpersonating!: (impersonatedUserId: string | null) => void;

  @project.Action
  loadItems!: () => Promise<void>;

  @project.Action
  selectCurrentItemId!: (currentItem: string | null) => Promise<void>;

  @project.Action
  reloadCurrentItem!: () => Promise<void>;

  @Action
  logout!: () => Promise<void>;

  @project.Getter
  items!: ProjectList;

  @project.Getter
  currentItem!: Project;

  @version.Getter
  nodePitVersionInfo!: IVersionInfo | null;

  @version.Action
  private checkNodePitVersion!: () => Promise<void>;

  @Watch('$route')
  async onRouteChanged() {
    const projectId = this.$route.params.projectId;
    const project = this.items.projects.find((p) => p._id === projectId);
    if (project && this.currentItem._id !== project._id) {
      await this.selectCurrentItemId(project._id);
    }
  }

  get projectId() {
    return this.currentItem?._id;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    window.localStorage.setItem(this.localStorageKey, String(this.collapsed));
  }

  async created(): Promise<void> {
    this.loadStats();
    this.checkNodePitVersion();
    GlobalEvents.$on('requestCompleted', this.updateHandler);
  }

  destroyed(): void {
    GlobalEvents.$off('requestCompleted', this.updateHandler);
  }

  private updateHandler(args: unknown) {
    if (
      args != null &&
      typeof args === 'object' &&
      'init' in args &&
      args.init != null &&
      typeof args.init === 'object' &&
      'method' in args.init &&
      args.init.method != null &&
      typeof args.init.method === 'string' &&
      ['POST', 'PUT', 'DELETE'].includes(args.init.method)
    ) {
      this.loadStats();
      this.reloadCurrentItem();
    }
  }

  private async loadStats() {
    this.stats = await getStats();
  }

  get settingsBadgeCount(): number | undefined {
    return this.nodePitVersionInfo ? 1 : undefined;
  }

  async onSelectProject(projectId: string | null) {
    await this.selectCurrentItemId(projectId);
    if (projectId) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await router.push({ params: { projectId } }).catch(() => {});
    } else {
      await router.push({ name: 'dashboard' });
    }
  }

  stopImpersonating(): void {
    window.close();
  }
}
