import { NavigationGuard } from 'vue-router';
import store from '../../store';

export const impersonateGuard: NavigationGuard = async (to, _from, next) => {
  const impersonate = to.query.impersonate;
  if (typeof impersonate === 'string') {
    store.dispatch('impersonation/startImpersonating', impersonate);
  }
  next();
};
