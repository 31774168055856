




























































































import { ability } from '@/auth/abilities';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownAZ, faBan, faCheck, faMasksTheater, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SortUsers, User, UserList } from '../api';
import DateTime from '../components/DateTime.vue';
import SearchInput from '../components/SearchInput.vue';
import SortInput from '../components/SortInput.vue';
import UserRoleBadge from '../components/UserRoleBadge.vue';
import { IToast } from '../store/toast.module';

library.add(faPlus, faBan, faCheck, faMasksTheater, faSearch, faArrowDownAZ);

const toast = namespace('toast');
const impersonation = namespace('impersonation');
const user = namespace('user');

@Component({
  components: { DateTime, SearchInput, UserRoleBadge, SortInput },
})
export default class UserListComponent extends Vue {
  private pollInterval: number | undefined;
  private q = '';
  sort: SortUsers = 'email';

  @user.Getter('users')
  userList!: UserList;

  @user.Action
  loadUsers!: (params?: { q?: string; sort?: SortUsers }) => Promise<void>;

  @user.Action
  deleteUser!: (id: string) => Promise<void>;

  @user.Action
  confirmUser!: (id: string) => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @impersonation.Getter
  impersonatedUserId!: string | null;

  async mounted(): Promise<void> {
    await this.load();
    this.pollInterval = setInterval(() => void this.load(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  async onSearchInput(q: string) {
    this.q = q;
    this.load();
  }

  async onSortInput(sort: SortUsers) {
    this.sort = sort;
    this.load();
  }

  async load() {
    await this.loadUsers({ q: this.q, sort: this.sort });
  }

  async remove(user: User): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete user ${user.email}`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await this.deleteUser(user._id);
      await this.loadUsers({ q: this.q });
      this.setToast({ message: `User ${user.email} was deleted.` });
    }
  }

  async confirm(user: User): Promise<void> {
    await this.confirmUser(user._id);
    await this.loadUsers({ q: this.q });
    this.setToast({ message: `User ${user.email} was confirmed.` });
  }

  impersonate(user: User): void {
    const routeData = this.$router.resolve({ path: '/', query: { impersonate: user._id } });
    window.open(routeData.href, '_blank');
  }

  showUserRoleBadge(user: User): boolean {
    return !user.confirmedAt || user.role !== 'user';
  }

  get sortOptions(): { key: SortUsers; label: string }[] {
    return [
      { key: 'email', label: 'Email' },
      { key: 'createdAt', label: 'Registered ↓' },
      { key: '-createdAt', label: 'Registered ↑' },
      { key: 'lastLoginAt', label: 'Last Login ↓' },
      { key: '-lastLoginAt', label: 'Last Login ↑' },
    ];
  }

  canImpersonate(user: User): boolean {
    const isAlreadyImpersonating = typeof this.impersonatedUserId === 'string';
    return !isAlreadyImpersonating && ability.can('impersonate', user);
  }
}
