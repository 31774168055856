import ExecutorRunList from '@/views/ExecutorRunList.vue';
import FileEdit from '@/views/FileEdit.vue';
import Vue from 'vue';
import Router, { RawLocation, Route } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import store from '../store';
import Empty from '../views/Empty.vue';
import EnvironmentEdit from '../views/EnvironmentEdit.vue';
import EnvironmentList from '../views/EnvironmentList.vue';
import ExecutorEdit from '../views/ExecutorEdit.vue';
import ExecutorList from '../views/ExecutorList.vue';
import FileList from '../views/FileList.vue';
import Login from '../views/Login.vue';
import Pending from '../views/Pending.vue';
import ProjectEdit from '../views/ProjectEdit.vue';
import ProjectList from '../views/ProjectList.vue';
import RecoverPassword from '../views/RecoverPassword.vue';
import Register from '../views/Register.vue';
import ResetPassword from '../views/ResetPassword.vue';
import RunList from '../views/RunList.vue';
import RunView from '../views/RunView.vue';
import ScheduleEdit from '../views/ScheduleEdit.vue';
import ScheduleList from '../views/ScheduleList.vue';
import Settings from '../views/Settings.vue';
import UserEdit from '../views/UserEdit.vue';
import UserList from '../views/UserList.vue';
import Wrapper from '../views/Wrapper.vue';
import { canEnter } from './guards/canEnter.guard';
import { canEnterProjectGuard } from './guards/canEnterProject.guard';
import hasUserGuard from './guards/hasUser.guard';
import { impersonateGuard } from './guards/impersonate.guard';
import { isAdmin } from './guards/isAdmin.guard';
import isLoggedInGuard from './guards/isLoggedIn.guard';
import { loadProjectsGuard } from './guards/loadProjects.guard';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/',
      component: Empty,
      beforeEnter: multiguard([impersonateGuard, hasUserGuard]),
      meta: { breadcrumb: null },
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login,
        },
        {
          path: '/recover-password',
          name: 'recover-password',
          component: RecoverPassword,
        },
        {
          path: '/reset-password/:resetId/:token',
          name: 'reset-password',
          component: ResetPassword,
        },
        {
          path: '/pending',
          name: 'pending',
          component: Pending,
        },
        {
          path: '/',
          component: Wrapper,
          redirect: { name: 'dashboard' },
          beforeEnter: multiguard([isLoggedInGuard, loadProjectsGuard]),
          children: [
            {
              path: 'dashboard',
              name: 'dashboard',
              redirect: { name: 'projects' },
            },
            {
              path: 'executors',
              component: Empty,
              meta: { breadcrumb: 'Executors' },
              beforeEnter: canEnter('list', 'executor'),
              children: [
                {
                  path: '',
                  name: 'executors',
                  component: ExecutorList,
                  meta: {
                    largeWidth: true,
                  },
                },
                {
                  path: ':id',
                  component: Empty,
                  meta: {
                    breadcrumb: () => store.state.executor.executor?.description,
                  },
                  children: [
                    {
                      path: '',
                      name: 'edit-executor',
                      component: ExecutorEdit,
                    },
                    {
                      path: 'runs',
                      name: 'executor-runs',
                      component: ExecutorRunList,
                      beforeEnter: canEnter('list', 'run'),
                      meta: {
                        breadcrumb: 'Runs',
                        largeWidth: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: 'projects',
              component: Empty,
              meta: { breadcrumb: 'Projects' },
              beforeEnter: canEnter('list', 'project'),
              children: [
                {
                  path: '',
                  name: 'projects',
                  component: ProjectList,
                  meta: {
                    fullWidth: true,
                  },
                },
                {
                  path: 'new',
                  name: 'new-project',
                  component: ProjectEdit,
                  meta: { breadcrumb: 'New' },
                },
                {
                  path: ':projectId',
                  component: Empty,
                  beforeEnter: canEnterProjectGuard,
                  meta: {
                    breadcrumb: () => store.state.project.currentItem?.description,
                  },
                  children: [
                    {
                      path: '',
                      name: 'edit-project',
                      component: ProjectEdit,
                    },
                    {
                      path: 'environments',
                      component: Empty,
                      meta: { breadcrumb: 'Environments' },
                      beforeEnter: canEnter('list', 'environment'),
                      children: [
                        {
                          path: '',
                          name: 'environments',
                          component: EnvironmentList,
                          meta: {
                            largeWidth: true,
                          },
                        },
                        {
                          path: 'new',
                          name: 'new-environment',
                          component: EnvironmentEdit,
                          meta: { breadcrumb: 'New' },
                        },
                        {
                          path: ':id',
                          name: 'edit-environment',
                          component: EnvironmentEdit,
                          meta: {
                            breadcrumb: () => store.state.environment.item?.description,
                          },
                        },
                      ],
                    },
                    {
                      path: 'files',
                      component: Empty,
                      meta: { breadcrumb: 'Files' },
                      beforeEnter: canEnter('list', 'file'),
                      children: [
                        {
                          path: '',
                          name: 'files',
                          component: FileList,
                          meta: {
                            largeWidth: true,
                          },
                        },
                        {
                          path: ':id',
                          name: 'edit-file',
                          component: FileEdit,
                          meta: {
                            breadcrumb: () => store.state.file.item?.originalName,
                          },
                        },
                      ],
                    },
                    {
                      path: 'schedules',
                      component: Empty,
                      meta: { breadcrumb: 'Schedules' },
                      beforeEnter: canEnter('list', 'schedule'),
                      children: [
                        {
                          path: '',
                          name: 'schedules',
                          component: ScheduleList,
                          meta: {
                            largeWidth: true,
                          },
                        },
                        {
                          path: 'new',
                          name: 'new-schedule',
                          component: ScheduleEdit,
                          meta: { breadcrumb: 'New Schedule' },
                        },
                        {
                          path: ':id',
                          name: 'edit-schedule',
                          component: ScheduleEdit,
                          meta: {
                            breadcrumb: () => store.state.schedule.item?.description,
                          },
                        },
                      ],
                    },
                    {
                      path: 'runs',
                      component: Empty,
                      meta: { breadcrumb: 'Runs' },
                      beforeEnter: canEnter('list', 'run'),
                      children: [
                        {
                          path: '',
                          name: 'runs',
                          component: RunList,
                          meta: {
                            largeWidth: true,
                          },
                        },
                        {
                          path: ':id',
                          name: 'view-run',
                          component: RunView,
                          meta: {
                            breadcrumb: () =>
                              store.state.run.item
                                ? `${store.state.run.item.description} (${store.state.run.item._id.slice(-8)})`
                                : undefined,
                            fullWidth: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'users',
              component: Empty,
              meta: { breadcrumb: 'Users' },
              beforeEnter: multiguard([isAdmin, canEnter('list', 'user')]),
              children: [
                {
                  path: '',
                  name: 'users',
                  component: UserList,
                  meta: {
                    largeWidth: true,
                  },
                },
                {
                  path: 'new',
                  name: 'new-user',
                  component: UserEdit,
                  meta: { breadcrumb: 'New User' },
                },
                {
                  path: ':id',
                  name: 'edit-user',
                  component: UserEdit,
                  meta: {
                    breadcrumb: () => store.state.user.user?.email,
                  },
                },
              ],
            },
            {
              path: 'settings',
              name: 'settings',
              component: Settings,
              meta: { breadcrumb: 'Settings' },
              beforeEnter: canEnter('get', 'settings'),
            },
          ],
        },
      ],
    },
    {
      // not found route - just redirect to '/'
      path: '*',
      redirect: '/',
    },
  ],
});

/* patch the router.resolve function to include the `impersonate` parameter;
 * this way we can always propagate it and opening a link in a new tab will
 * keep the impersonated state */

const originalResolve = router.resolve;

router.resolve = (to: RawLocation, current?: Route, append?: boolean) => {
  const modifiedTo = typeof to === 'string' ? { path: to } : { ...to };

  const impersonatedUserId = store.state.impersonation.impersonatedUserId;
  if (typeof impersonatedUserId === 'string') {
    modifiedTo.query ??= {};
    modifiedTo.query.impersonate = impersonatedUserId;
  }

  return originalResolve.bind(router)(modifiedTo, current, append);
};

/* remove `impersonate` parameter from the address bar after the navigation */

router.afterEach((to) => {
  const query = { ...to.query };

  if (query.impersonate) {
    delete query.impersonate;
  }

  if (Object.keys(query).length !== Object.keys(to.query).length) {
    router.replace({ path: to.path, query });
  }
});

export default router;
